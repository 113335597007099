<template>
  <div
    class="iHeader"
    :class="{'iHeader--active': this.$route.name !== 'main'}"
  >
    <header>
      <div class="header__bi">
        <button @click="movePage('/introduction/main')">QPICK</button>
      </div>
      <div class="header__gnb">
        <ul>
          <li
            @click.stop="clickDropdown('function', true)"
            @mouseover="hoverDropdown('function', true)"
            @mouseout="hoverDropdown('function', false)"
          >
            <button class="lnk__dropdown">서비스</button>
            <ol
              class="gnb__dropdown"
              v-show="dropdownVisible.function"
              v-click-outside:clickDropdown="'function'"
            >
              <li class="ga_f_1">
                <button @click="movePage('/introduction/function01')">
                  디자인 관리
                </button>
              </li>
              <li class="ga_f_2">
                <button @click="movePage('/introduction/function02')">
                  반응형 지원
                </button>
              </li>
              <li class="ga_f_3">
                <button @click="movePage('/introduction/function03')">
                  솔루션 연결
                </button>
              </li>
            </ol>
          </li>
          <li
            @click.stop="clickDropdown('design', true)"
            @mouseover="hoverDropdown('design', true)"
            @mouseout="hoverDropdown('design', false)"
          >
            <button class="lnk__dropdown">디자인</button>
            <ol
              class="gnb__dropdown"
              v-show="dropdownVisible.design"
              v-click-outside:clickDropdown="'design'"
            >
              <li class="ga_f_4">
                <button @click="movePage('/introduction/theme-list?id=3')">
                  테마
                </button>
              </li>
              <li class="ga_f_5">
                <button @click="movePage('/introduction/component-list')">
                  컴포넌트
                </button>
              </li>
            </ol>
          </li>
          <li
            @click.stop="clickDropdown('helpcenter', true)"
            @mouseover="hoverDropdown('helpcenter', true)"
            @mouseout="hoverDropdown('helpcenter', false)"
          >
            <button class="lnk__dropdown">헬프센터</button>
            <ol
              class="gnb__dropdown"
              v-show="dropdownVisible.helpcenter"
              v-click-outside:clickDropdown="'helpcenter'"
            >
              <li class="ga_f_6">
                <button @click="openPage('/gallery?id=1')">시작하기</button>
              </li>
              <li class="ga_f_7">
                <button @click="openPage('/board?id=2')">사용팁</button>
              </li>
              <li class="ga_f_8">
                <button @click="openPage('/board?id=3')">공지사항</button>
              </li>
              <li class="ga_f_9">
                <button @click="openPage('/board?id=4')">FAQ</button>
              </li>
              <li class="ga_f_10">
                <button @click="beforeItemEvent(inquiryMenu)">1:1 문의</button>
              </li>
            </ol>
          </li>
        </ul>
      </div>
      <ul class="header__user">
        <li
          v-show="getLoginStatus"
          class="header__user--active"
          @click.stop="clickDropdown('account', true)"
          @mouseover="hoverDropdown('account', true)"
          @mouseout="hoverDropdown('account', false)"
        >
          <button class="lnk__user">계정</button>
          <ol
            class="gnb__dropdown"
            v-show="dropdownVisible.account"
            v-click-outside:clickDropdown="'account'"
          >
            <li class="gnb__dropdown--id">
              <span v-if="getLoggedData.grade === 0">
                {{ "준회원(" + getLoggedData.id + ")" }}
              </span>
              <span v-else>
                {{ getLoggedData.privacy?.email }}
              </span>
            </li>
            <li v-if="getLoggedData.grade === 0">
              <button
                class="lnk__transform"
                @click="movePage('/accounts/gradeup')"
              >
                회원전환
              </button>
            </li>
            <li v-else>
              <button class="lnk__lock" @click="movePage('/accounts/personal')">
                계정정보
              </button>
            </li>
            <li>
              <button class="lnk__logout" @click="userLogout">로그아웃</button>
            </li>
          </ol>
        </li>
        <li v-show="getLoginStatus">
          <button
            class="lnk__join ga_f_12"
            @click="movePage('/admin/dashboard')"
          >
            나의 테마관리
          </button>
        </li>
        <li v-show="!getLoginStatus">
          <button
            class="lnk__login ga_f_13"
            @click="beforeLoginPageLoad('/accounts/login')"
          >
            로그인
          </button>
        </li>
        <li v-show="!getLoginStatus">
          <button
            class="lnk__join ga_f_11"
            @click="movePage('/accounts/signup')"
          >
            무료 시작하기
          </button>
        </li>
        <li><button class="lnk__menu" @click="toggleAside">메뉴</button></li>
      </ul>
    </header>
    <transition name="opacity" mode="out-in">
      <div class="dimmed" v-show="asideVisible" @click="toggleAside"></div>
    </transition>
    <transition name="slide" mode="out-in">
      <div class="aside" v-show="asideVisible">
        <aside>
          <div class="aside__top">
            <button class="aside__close" @click="toggleAside">닫기</button>
          </div>
          <div class="aside__member" v-show="getLoginStatus">
            <p class="aside__user" v-if="getLoggedData.grade === 0">
              {{ "준회원(" + getLoggedData.id + ")" }}
            </p>
            <p class="aside__user" v-else>
              {{ getLoggedData.privacy?.email }}
            </p>
            <button
              class="aside__join ga_f_12"
              @click="movePage('/admin/dashboard')"
            >
              나의 테마관리
            </button>
            <div class="aside__member--link">
              <button
                v-if="getLoggedData.grade === 0"
                class="lnk__transform"
                @click="movePage('/accounts/gradeup')"
              >
                회원전환
              </button>
              <button
                v-else
                class="lnk__lock"
                @click="movePage('/accounts/personal')"
              >
                계정정보
              </button>
              <button class="lnk__logout" @click="userLogout">로그아웃</button>
            </div>
          </div>
          <div class="aside__member" v-show="!getLoginStatus">
            <button
              class="aside__join ga_f_11"
              @click="movePage('/accounts/signup')"
            >
              무료 시작하기
            </button>
            <button
              class="aside__login ga_f_13"
              @click="beforeLoginPageLoad('/accounts/login')"
            >
              로그인/회원가입
            </button>
          </div>
          <div class="aside__snb">
            <p>
              <button
                type="button"
                :class="activeSnb('service')"
                @click="toggleSnb('service')"
              >
                서비스
              </button>
            </p>
            <ul v-show="snbVisible.service">
              <li class="ga_f_1">
                <button @click="movePage('/introduction/function01')">
                  디자인 관리
                </button>
              </li>
              <li class="ga_f_2">
                <button @click="movePage('/introduction/function02')">
                  반응형 지원
                </button>
              </li>
              <li class="ga_f_3">
                <button @click="movePage('/introduction/function03')">
                  솔루션 연결
                </button>
              </li>
            </ul>
            <p>
              <button
                type="button"
                :class="activeSnb('design')"
                @click="toggleSnb('design')"
              >
                디자인
              </button>
            </p>
            <ul v-show="snbVisible.design">
              <li class="ga_f_4">
                <button @click="movePage('/introduction/theme-list?id=3')">
                  테마
                </button>
              </li>
              <li class="ga_f_5">
                <button @click="movePage('/introduction/component-list')">
                  컴포넌트
                </button>
              </li>
            </ul>
            <p>
              <button
                type="button"
                :class="activeSnb('helpcenter')"
                @click="toggleSnb('helpcenter')"
              >
                헬프센터
              </button>
            </p>
            <ul v-show="snbVisible.helpcenter">
              <li class="ga_f_6">
                <button @click="openPage('/gallery?id=1')">시작하기</button>
              </li>
              <li class="ga_f_7">
                <button @click="openPage('/board?id=2')">사용팁</button>
              </li>
              <li class="ga_f_8">
                <button @click="openPage('/board?id=3')">공지사항</button>
              </li>
              <li class="ga_f_9">
                <button @click="openPage('/board?id=4')">FAQ</button>
              </li>
              <li class="ga_f_10">
                <button @click="beforeItemEvent(inquiryMenu)">1:1 문의</button>
              </li>
            </ul>
          </div>
        </aside>
      </div>
    </transition>
  </div>
</template>

<script>
import {getAuthToken, removeAuthToken} from "@/utils/auth";
import channelService from "@/utils/channel.js";
import {createNamespacedHelpers} from "vuex";

const ModuleAuth = createNamespacedHelpers("ModuleAuth");
const ModuleAlert = createNamespacedHelpers("ModuleAlert");
const ModuleIntroCommon = createNamespacedHelpers("ModuleIntroCommon");

export default {
  data() {
    return {
      width: null,
      asideVisible: false,
      snbVisible: {
        service: true,
        design: true,
        helpcenter: false
      },
      dropdownVisible: []
    };
  },
  computed: {
    inquiryMenu() {
      return this.getMenuList[2].children[4];
    },
    ...ModuleIntroCommon.mapGetters(["getMenuList", "getRedirectPage"]),
    ...ModuleAuth.mapGetters(["getLoggedData", "getLoginStatus"])
  },
  watch: {
    asideVisible(data) {
      if (data) {
        document.querySelector("body").style.overflowY = "hidden";
      } else {
        document.querySelector("body").style.overflowY = "";
      }
    }
  },
  methods: {
    onScroll() {
      if (this.$route.name !== "main") return;

      if (window.scrollY === 0) {
        document
          .querySelector(".iHeader")
          .setAttribute("class", "iHeader iHeader--dark");
      } else {
        document.querySelector(".iHeader").setAttribute("class", "iHeader");
      }
    },
    resizeWidth() {
      this.width = window.innerWidth;
      if (this.width >= 830) {
        this.asideVisible = false;
      }
    },
    // 로그아웃
    userLogout() {
      this.actLogout().then(() => {
        removeAuthToken();
        localStorage.removeItem("userId");
        this.setLogged({});
        this.setLoginStatus(false);

        // 테마목록 페이지 내 로그아웃시 쿼리 스트링 삭제 (어드민 진입 상태값 삭제)
        if (this.$route.path === "/introduction/theme-list") {
          this.$router.replace({
            query: {
              id: this.$route.query.id
            }
          });
        }
      });
    },
    // 다른 큐픽 사이트에서 로그인을 한 경우
    beforeLoginPageLoad(path) {
      let authToken = getAuthToken();
      if (authToken) {
        this.actLogged()
          .then(response => {
            switch (response.status) {
              case 200:
                this.setLoginStatus(true);
                this.setRedirectPage(null);
                this.channelTalk(true);
                break;

              case 401:
              default:
                removeAuthToken();
                this.setLoginStatus(false);
                break;
            }
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.movePage(path);
      }
    },
    beforeItemEvent(data) {
      if (data.id === 407 && !this.getLoginStatus) {
        this.callback = data.clickTarget;
        this.setAlert({
          isVisible: true,
          message: "1:1문의는 로그인 후 이용할 수 있습니다.",
          event: "login"
        });
      } else {
        this.openPage("/inquiry");
      }
    },
    // 페이지 이동
    movePage(path) {
      this.dropdownVisible = [];
      this.toggleAside();
      this.$emit("movePage", path);
    },
    // 페이지 오픈
    openPage(path) {
      window.open(process.env.VUE_APP_EXTERNAL_qpick_help_URL + path);
    },
    // 채널톡 실행
    channelTalk(isLogin) {
      let settings = {};
      let profile = {};

      if (isLogin) {
        settings.pluginKey = process.env.VUE_APP_AUTH_CHANNEL_TALK_PLUGIN_KEY;
        settings.member = true;
        profile.name = this.getLoggedData.privacy.name;
        profile.joinTime = this.getLoggedData.createdAt;
        profile.qpickId = this.getLoggedData.privacy.email;
        settings.memberId = this.getLoggedData.id;
        settings.profile = profile;

        channelService.boot(settings);
      } else {
        settings.pluginKey = process.env.VUE_APP_AUTH_CHANNEL_TALK_PLUGIN_KEY;
        settings.member = false;

        channelService.boot(settings);
      }
    },
    // aside toggle
    toggleAside(value) {
      this.asideVisible = !value ? value : !this.asideVisible;
    },
    // snb toggle
    toggleSnb(key) {
      this.$set(this.snbVisible, key, !this.snbVisible[key]);
    },
    // snb class binding
    activeSnb(key) {
      return {
        active: this.snbVisible[key]
      };
    },
    // dropdown click
    clickDropdown(key, value) {
      if (this.width >= 1024) return;
      this.dropdownVisible = [];
      this.$set(
        this.dropdownVisible,
        key,
        !value ? value : !this.dropdownVisible[key]
      );
    },
    // dropdown hover
    hoverDropdown(key, value) {
      if (this.width < 1024) return;
      this.$set(this.dropdownVisible, key, value);
    },
    ...ModuleAlert.mapMutations(["setAlert"]),
    ...ModuleIntroCommon.mapMutations(["setRedirectPage"]),
    ...ModuleAuth.mapActions(["actLogged", "actLogout"]),
    ...ModuleAuth.mapMutations(["setLogged", "setLoginStatus"])
  },
  created() {
    this.$EventBus.$on("alertCancel", event => {
      if (event === "login" && this.callback) {
        this.$router.push(
          "/accounts/login?callback=" + encodeURIComponent(this.callback)
        );
      }
    });
  },
  mounted() {
    this.onScroll();
    this.resizeWidth();
    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("resize", this.resizeWidth);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("resize", this.resizeWidth);
  }
};
</script>

<style lang="scss" scoped>
div,
button {
  font-family: "Pretendard", "Malgun Gothic", sans-serif;
  color: #1c1e23;
  line-height: 1.25;
}
.opacity-enter-active,
.opacity-leave-active {
  transition: opacity 0.5s ease;
  -webkit-transition: opacity 0.5s ease;
  -webkit-transition: -webkit-opacity 0.5s ease;
}
.opacity-enter-from,
.opacity-leave-to {
  opacity: 0;
}
.slide-leave-active,
.slide-enter-active {
  transition: 0.5s ease;
  -webkit-transition: transform 0.5s ease;
  -webkit-transition: -webkit-transform 0.5s ease;
}
.slide-leave-to,
.slide-enter {
  transform: translate(100%, 0);
  -webkit-transform: translate(100%, 0);
}
.iHeader {
  z-index: 3;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  &--active {
    box-shadow: unset;
    border-bottom: 1px solid #f5f5f5;
  }
  &--dark {
    background-color: #1c1e23;
    box-shadow: none;

    .header__bi button {
      background-image: url(~@/assets/images/intro/bi_qpick_white.svg);
    }
    .header__gnb ul > li > button {
      color: #fff;
      background-image: url(~@/assets/images/intro/arr_down_white.svg);
    }
    .lnk__login {
      color: #fff;
    }
    .lnk__user {
      background-image: url(~@/assets/images/intro/ico_user_white.svg);
    }
    .lnk__menu {
      background-image: url(~@/assets/images/intro/ico_menu_white.svg);
    }
  }
  header {
    position: relative;
    display: flex;
    column-gap: 80px;
    align-items: center;
    max-width: 1648px;
    margin: 0 auto;
    padding: 0 24px;
    box-sizing: border-box;
  }
}
.header__bi {
  button {
    display: block;
    width: 124px;
    height: 40px;
    background-image: url(~@/assets/images/intro/bi_header.svg);
    background-repeat: no-repeat;
    background-size: contain;
    text-indent: -9999px;
  }
}
.header__gnb {
  ul {
    display: flex;
  }
  ul > li {
    position: relative;
  }
  ul > li:last-child {
    position: relative;
  }
  ul > li:last-child::before {
    position: absolute;
    top: 18px;
    left: 0;
    height: 20px;
    border-left: 1px solid #d4d4d4;
    content: "";
  }
  ul > li > button {
    color: #1c1e23;
    margin-top: 16px;
    padding-right: 20px;
    padding-bottom: 16px;
    background-image: url(~@/assets/images/intro/arr_down.svg);
    background-repeat: no-repeat;
    background-position: right 4px top 8px;
  }
}
.gnb__dropdown {
  position: absolute;
  top: 48px;
  left: -16px;
  width: 232px;
  padding: 32px 0;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  &--id {
    margin-bottom: 8px;
    padding: 0 16px;

    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 8px 0;
      font-size: 15px;
      border-bottom: 1px solid #dfe2e9;
    }
  }
  li {
    button {
      width: 100%;
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 16px;
      box-sizing: border-box;

      &:hover {
        background-color: #eff2f8;
      }
    }
    .lnk__transform,
    .lnk__lock,
    .lnk__logout {
      padding-left: 36px;
      background-repeat: no-repeat;
      background-position: 16px center;
    }
    .lnk__transform {
      background-image: url(~@/assets/images/intro/bul_transform.svg);
    }
    .lnk__lock {
      background-image: url(~@/assets/images/intro/bul_lock.svg);
    }
    .lnk__logout {
      background-image: url(~@/assets/images/intro/bul_logout.svg);
    }
  }
}
.header__user {
  display: flex;
  align-items: center;
  column-gap: 16px;
  margin-left: auto;

  .header__user--active {
    position: relative;
  }
  .header__user--active .gnb__dropdown {
    top: 36px;
    left: auto;
    right: -36px;
  }
}
.lnk__login {
  color: #1c1e23;
}
.lnk__join {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 16px;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  border-radius: 20px;
  background-color: #54c7a2;
}
.lnk__user {
  display: block;
  width: 32px;
  height: 40px;
  padding-bottom: 4px;
  background-image: url(~@/assets/images/intro/ico_user.svg);
  background-repeat: no-repeat;
  background-position: center;
  text-indent: -9999px;
}
.lnk__menu {
  display: block;
  width: 24px;
  height: 24px;
  background-image: url(~@/assets/images/intro/ico_menu.svg);
  background-repeat: no-repeat;
  background-position: center;
  text-indent: -9999px;
  color: #fff;
}
.dimmed {
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  overflow-y: auto;
}
.aside {
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  max-width: 360px;
  width: 100%;
  height: 100vh;
  margin-left: auto;
  height: 100vh;
  overflow-y: auto;

  aside {
    z-index: 11;
    max-width: 360px;
    width: 100%;
    height: 100vh;
    margin-left: auto;
    background-color: #fff;
  }
  &__top {
    height: 56px;
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  &__close {
    width: 24px;
    height: 24px;
    margin-left: auto;
    background-image: url(~@/assets/images/intro/btn_close.svg);
    background-repeat: no-repeat;
    background-position: center;
    text-indent: -9999px;
  }
  &__member {
    padding: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    border-bottom: 8px solid #f5f5f5;

    &--link {
      color: #8e939b;
      height: 24px;
      display: flex;
      align-items: center;

      button {
        position: relative;
        color: #8e939b;
        font-size: 13px;
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:last-child:before {
          position: absolute;
          top: 4px;
          left: 0;
          height: 14px;
          border-left: 1px solid #d4d4d4;
          content: "";
        }
      }
    }
  }
  &__user {
    min-height: 24px;
    line-height: 24px;
    margin-bottom: 8px;
    padding-left: 28px;
    background-image: url(~@/assets/images/intro/ico_member.svg);
    background-repeat: no-repeat;
    background-position: left center;
    color: #494d53;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__snb {
    p {
      height: 56px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      background-color: #fff;

      &:nth-child(5) {
        border-top: 1px solid #f5f5f5;
      }
      button {
        display: block;
        width: 100%;
        height: 100%;
        text-align: left;
        background-size: 12px auto;
        background-image: url(~@/assets/images/intro/arr_down.svg);
        background-repeat: no-repeat;
        background-position: right 14px center;
      }
      button.active {
        background-image: url(~@/assets/images/intro/arr_up.svg);
      }
    }
    ul {
      padding: 8px;
      background-color: #f8fafc;
    }
    li button {
      width: 100%;
      height: 40px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      color: #494d53;
      font-size: 15px;
    }
    li button:hover {
      background-color: #eff2f8;
      border-radius: 8px;
      color: #1c1e23;
    }
  }
  &__join {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 16px;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    border-radius: 20px;
    background-color: #54c7a2;
  }
  &__login {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 16px;
    color: #1c1e23;
    font-size: 16px;
    font-weight: bold;
    border-radius: 20px;
    border: 1px solid #676b74;
  }
}
@media (min-width: 1024px) {
  .iHeader header {
    height: 104px;
    column-gap: 80px;
  }
  .header__gnb ul {
    column-gap: 40px;
  }
  .header__gnb ul > li:last-child {
    padding-left: 40px;
  }
  .header__user > li:last-child {
    display: none;
  }
}
@media (max-width: 1023px) and (min-width: 768px) {
  .iHeader header {
    height: 72px;
  }
  @media (min-width: 830px) {
    .iHeader header {
      column-gap: 24px;
    }
    .header__gnb ul {
      column-gap: 24px;
    }
    .header__gnb ul > li:last-child {
      padding-left: 24px;
    }
    .header__user > li:last-child {
      display: none;
    }
  }
  @media (max-width: 829px) {
    .header__gnb {
      display: none;
    }
  }
}
@media (max-width: 767px) {
  .iHeader header {
    padding: 0 16px;
    height: 56px;
  }
  .header__bi button {
    width: 92px;
    height: 24px;
  }
  .header__gnb {
    display: none;
  }
  .lnk__user,
  .lnk__login,
  .lnk__join {
    display: none;
  }
}
</style>
