var render = function () {
  var _vm$getLoggedData$pri, _vm$getLoggedData$pri2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "iHeader",
    class: {
      'iHeader--active': this.$route.name !== 'main'
    }
  }, [_c('header', [_c('div', {
    staticClass: "header__bi"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.movePage('/introduction/main');
      }
    }
  }, [_vm._v("QPICK")])]), _c('div', {
    staticClass: "header__gnb"
  }, [_c('ul', [_c('li', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.clickDropdown('function', true);
      },
      "mouseover": function mouseover($event) {
        return _vm.hoverDropdown('function', true);
      },
      "mouseout": function mouseout($event) {
        return _vm.hoverDropdown('function', false);
      }
    }
  }, [_c('button', {
    staticClass: "lnk__dropdown"
  }, [_vm._v("서비스")]), _c('ol', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.dropdownVisible.function,
      expression: "dropdownVisible.function"
    }, {
      name: "click-outside",
      rawName: "v-click-outside:clickDropdown",
      value: 'function',
      expression: "'function'",
      arg: "clickDropdown"
    }],
    staticClass: "gnb__dropdown"
  }, [_c('li', {
    staticClass: "ga_f_1"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.movePage('/introduction/function01');
      }
    }
  }, [_vm._v(" 디자인 관리 ")])]), _c('li', {
    staticClass: "ga_f_2"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.movePage('/introduction/function02');
      }
    }
  }, [_vm._v(" 반응형 지원 ")])]), _c('li', {
    staticClass: "ga_f_3"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.movePage('/introduction/function03');
      }
    }
  }, [_vm._v(" 솔루션 연결 ")])])])]), _c('li', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.clickDropdown('design', true);
      },
      "mouseover": function mouseover($event) {
        return _vm.hoverDropdown('design', true);
      },
      "mouseout": function mouseout($event) {
        return _vm.hoverDropdown('design', false);
      }
    }
  }, [_c('button', {
    staticClass: "lnk__dropdown"
  }, [_vm._v("디자인")]), _c('ol', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.dropdownVisible.design,
      expression: "dropdownVisible.design"
    }, {
      name: "click-outside",
      rawName: "v-click-outside:clickDropdown",
      value: 'design',
      expression: "'design'",
      arg: "clickDropdown"
    }],
    staticClass: "gnb__dropdown"
  }, [_c('li', {
    staticClass: "ga_f_4"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.movePage('/introduction/theme-list?id=3');
      }
    }
  }, [_vm._v(" 테마 ")])]), _c('li', {
    staticClass: "ga_f_5"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.movePage('/introduction/component-list');
      }
    }
  }, [_vm._v(" 컴포넌트 ")])])])]), _c('li', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.clickDropdown('helpcenter', true);
      },
      "mouseover": function mouseover($event) {
        return _vm.hoverDropdown('helpcenter', true);
      },
      "mouseout": function mouseout($event) {
        return _vm.hoverDropdown('helpcenter', false);
      }
    }
  }, [_c('button', {
    staticClass: "lnk__dropdown"
  }, [_vm._v("헬프센터")]), _c('ol', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.dropdownVisible.helpcenter,
      expression: "dropdownVisible.helpcenter"
    }, {
      name: "click-outside",
      rawName: "v-click-outside:clickDropdown",
      value: 'helpcenter',
      expression: "'helpcenter'",
      arg: "clickDropdown"
    }],
    staticClass: "gnb__dropdown"
  }, [_c('li', {
    staticClass: "ga_f_6"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.openPage('/gallery?id=1');
      }
    }
  }, [_vm._v("시작하기")])]), _c('li', {
    staticClass: "ga_f_7"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.openPage('/board?id=2');
      }
    }
  }, [_vm._v("사용팁")])]), _c('li', {
    staticClass: "ga_f_8"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.openPage('/board?id=3');
      }
    }
  }, [_vm._v("공지사항")])]), _c('li', {
    staticClass: "ga_f_9"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.openPage('/board?id=4');
      }
    }
  }, [_vm._v("FAQ")])]), _c('li', {
    staticClass: "ga_f_10"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.beforeItemEvent(_vm.inquiryMenu);
      }
    }
  }, [_vm._v("1:1 문의")])])])])])]), _c('ul', {
    staticClass: "header__user"
  }, [_c('li', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.getLoginStatus,
      expression: "getLoginStatus"
    }],
    staticClass: "header__user--active",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.clickDropdown('account', true);
      },
      "mouseover": function mouseover($event) {
        return _vm.hoverDropdown('account', true);
      },
      "mouseout": function mouseout($event) {
        return _vm.hoverDropdown('account', false);
      }
    }
  }, [_c('button', {
    staticClass: "lnk__user"
  }, [_vm._v("계정")]), _c('ol', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.dropdownVisible.account,
      expression: "dropdownVisible.account"
    }, {
      name: "click-outside",
      rawName: "v-click-outside:clickDropdown",
      value: 'account',
      expression: "'account'",
      arg: "clickDropdown"
    }],
    staticClass: "gnb__dropdown"
  }, [_c('li', {
    staticClass: "gnb__dropdown--id"
  }, [_vm.getLoggedData.grade === 0 ? _c('span', [_vm._v(" " + _vm._s("준회원(" + _vm.getLoggedData.id + ")") + " ")]) : _c('span', [_vm._v(" " + _vm._s((_vm$getLoggedData$pri = _vm.getLoggedData.privacy) === null || _vm$getLoggedData$pri === void 0 ? void 0 : _vm$getLoggedData$pri.email) + " ")])]), _vm.getLoggedData.grade === 0 ? _c('li', [_c('button', {
    staticClass: "lnk__transform",
    on: {
      "click": function click($event) {
        return _vm.movePage('/accounts/gradeup');
      }
    }
  }, [_vm._v(" 회원전환 ")])]) : _c('li', [_c('button', {
    staticClass: "lnk__lock",
    on: {
      "click": function click($event) {
        return _vm.movePage('/accounts/personal');
      }
    }
  }, [_vm._v(" 계정정보 ")])]), _c('li', [_c('button', {
    staticClass: "lnk__logout",
    on: {
      "click": _vm.userLogout
    }
  }, [_vm._v("로그아웃")])])])]), _c('li', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.getLoginStatus,
      expression: "getLoginStatus"
    }]
  }, [_c('button', {
    staticClass: "lnk__join ga_f_12",
    on: {
      "click": function click($event) {
        return _vm.movePage('/admin/dashboard');
      }
    }
  }, [_vm._v(" 나의 테마관리 ")])]), _c('li', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.getLoginStatus,
      expression: "!getLoginStatus"
    }]
  }, [_c('button', {
    staticClass: "lnk__login ga_f_13",
    on: {
      "click": function click($event) {
        return _vm.beforeLoginPageLoad('/accounts/login');
      }
    }
  }, [_vm._v(" 로그인 ")])]), _c('li', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.getLoginStatus,
      expression: "!getLoginStatus"
    }]
  }, [_c('button', {
    staticClass: "lnk__join ga_f_11",
    on: {
      "click": function click($event) {
        return _vm.movePage('/accounts/signup');
      }
    }
  }, [_vm._v(" 무료 시작하기 ")])]), _c('li', [_c('button', {
    staticClass: "lnk__menu",
    on: {
      "click": _vm.toggleAside
    }
  }, [_vm._v("메뉴")])])])]), _c('transition', {
    attrs: {
      "name": "opacity",
      "mode": "out-in"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.asideVisible,
      expression: "asideVisible"
    }],
    staticClass: "dimmed",
    on: {
      "click": _vm.toggleAside
    }
  })]), _c('transition', {
    attrs: {
      "name": "slide",
      "mode": "out-in"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.asideVisible,
      expression: "asideVisible"
    }],
    staticClass: "aside"
  }, [_c('aside', [_c('div', {
    staticClass: "aside__top"
  }, [_c('button', {
    staticClass: "aside__close",
    on: {
      "click": _vm.toggleAside
    }
  }, [_vm._v("닫기")])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.getLoginStatus,
      expression: "getLoginStatus"
    }],
    staticClass: "aside__member"
  }, [_vm.getLoggedData.grade === 0 ? _c('p', {
    staticClass: "aside__user"
  }, [_vm._v(" " + _vm._s("준회원(" + _vm.getLoggedData.id + ")") + " ")]) : _c('p', {
    staticClass: "aside__user"
  }, [_vm._v(" " + _vm._s((_vm$getLoggedData$pri2 = _vm.getLoggedData.privacy) === null || _vm$getLoggedData$pri2 === void 0 ? void 0 : _vm$getLoggedData$pri2.email) + " ")]), _c('button', {
    staticClass: "aside__join ga_f_12",
    on: {
      "click": function click($event) {
        return _vm.movePage('/admin/dashboard');
      }
    }
  }, [_vm._v(" 나의 테마관리 ")]), _c('div', {
    staticClass: "aside__member--link"
  }, [_vm.getLoggedData.grade === 0 ? _c('button', {
    staticClass: "lnk__transform",
    on: {
      "click": function click($event) {
        return _vm.movePage('/accounts/gradeup');
      }
    }
  }, [_vm._v(" 회원전환 ")]) : _c('button', {
    staticClass: "lnk__lock",
    on: {
      "click": function click($event) {
        return _vm.movePage('/accounts/personal');
      }
    }
  }, [_vm._v(" 계정정보 ")]), _c('button', {
    staticClass: "lnk__logout",
    on: {
      "click": _vm.userLogout
    }
  }, [_vm._v("로그아웃")])])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.getLoginStatus,
      expression: "!getLoginStatus"
    }],
    staticClass: "aside__member"
  }, [_c('button', {
    staticClass: "aside__join ga_f_11",
    on: {
      "click": function click($event) {
        return _vm.movePage('/accounts/signup');
      }
    }
  }, [_vm._v(" 무료 시작하기 ")]), _c('button', {
    staticClass: "aside__login ga_f_13",
    on: {
      "click": function click($event) {
        return _vm.beforeLoginPageLoad('/accounts/login');
      }
    }
  }, [_vm._v(" 로그인/회원가입 ")])]), _c('div', {
    staticClass: "aside__snb"
  }, [_c('p', [_c('button', {
    class: _vm.activeSnb('service'),
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.toggleSnb('service');
      }
    }
  }, [_vm._v(" 서비스 ")])]), _c('ul', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.snbVisible.service,
      expression: "snbVisible.service"
    }]
  }, [_c('li', {
    staticClass: "ga_f_1"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.movePage('/introduction/function01');
      }
    }
  }, [_vm._v(" 디자인 관리 ")])]), _c('li', {
    staticClass: "ga_f_2"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.movePage('/introduction/function02');
      }
    }
  }, [_vm._v(" 반응형 지원 ")])]), _c('li', {
    staticClass: "ga_f_3"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.movePage('/introduction/function03');
      }
    }
  }, [_vm._v(" 솔루션 연결 ")])])]), _c('p', [_c('button', {
    class: _vm.activeSnb('design'),
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.toggleSnb('design');
      }
    }
  }, [_vm._v(" 디자인 ")])]), _c('ul', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.snbVisible.design,
      expression: "snbVisible.design"
    }]
  }, [_c('li', {
    staticClass: "ga_f_4"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.movePage('/introduction/theme-list?id=3');
      }
    }
  }, [_vm._v(" 테마 ")])]), _c('li', {
    staticClass: "ga_f_5"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.movePage('/introduction/component-list');
      }
    }
  }, [_vm._v(" 컴포넌트 ")])])]), _c('p', [_c('button', {
    class: _vm.activeSnb('helpcenter'),
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.toggleSnb('helpcenter');
      }
    }
  }, [_vm._v(" 헬프센터 ")])]), _c('ul', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.snbVisible.helpcenter,
      expression: "snbVisible.helpcenter"
    }]
  }, [_c('li', {
    staticClass: "ga_f_6"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.openPage('/gallery?id=1');
      }
    }
  }, [_vm._v("시작하기")])]), _c('li', {
    staticClass: "ga_f_7"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.openPage('/board?id=2');
      }
    }
  }, [_vm._v("사용팁")])]), _c('li', {
    staticClass: "ga_f_8"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.openPage('/board?id=3');
      }
    }
  }, [_vm._v("공지사항")])]), _c('li', {
    staticClass: "ga_f_9"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.openPage('/board?id=4');
      }
    }
  }, [_vm._v("FAQ")])]), _c('li', {
    staticClass: "ga_f_10"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.beforeItemEvent(_vm.inquiryMenu);
      }
    }
  }, [_vm._v("1:1 문의")])])])])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }